import { VideoError } from '@grandstand-web/bally-web-shared/src/hooks/useVideoService'
import styled from 'styled-components'
import { breakpoints } from '../styles/breakpoints'
import { HeadingLabel, SubheadingLabel } from '../styles/fonts'
import { GrandstandButton } from './Buttons'
export type PlaybackState = 'loading' | 'playing' | 'paused'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  /* max-height: 1080px; */
  display: flex;
  align-items: center;
  justify-content: center;
`

const Overlay = styled.div`
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.65);
`

const Foreground = styled.div`
  position: relative;
  z-index: 20;
  width: 100%;
  height: auto;
  max-height: 1080px;
  display: grid;
  grid-template:
    'title' 1fr
    'message' 1fr
    'buttons' 1fr;
  gap: 16px 0;
  justify-items: center;
  padding: 40px;
  margin: 0 auto;
`

const LabelItem = styled.div`
  color: var(--on-background);
  text-align: center;
`
const Title = styled(LabelItem)`
  grid-area: title;
`
const Message = styled(LabelItem)`
  grid-area: message;
`
const Buttons = styled.div`
  grid-area: buttons;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
  gap: 16px;
  ${breakpoints.up('lg')} {
    grid-template-columns: repeat(auto-fill, 1fr);
    grid-template-rows: auto;
  }
`

const VideoErrorDisplay = ({ error }: { error: VideoError }) => {
  return (
    <Wrapper data-theme="dark">
      <Overlay />
      <Foreground>
        <Title>
          <HeadingLabel fontSize="5">{error.title}</HeadingLabel>
        </Title>
        <Message>
          <SubheadingLabel fontSize="4">{error.message}</SubheadingLabel>
        </Message>
        {error.buttons && (
          <Buttons>
            {error.buttons.map((button) => {
              return (
                <GrandstandButton
                  key={button.title}
                  buttonSize={button.size}
                  buttonStyle={button.style}
                  onClick={(_) => {
                    button.action()
                  }}
                >
                  {button.title.toUpperCase()}
                </GrandstandButton>
              )
            })}
          </Buttons>
        )}
      </Foreground>
    </Wrapper>
  )
}

export default VideoErrorDisplay
