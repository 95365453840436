import { Link } from '@grandstand/presentation-models/types/primitives/link'
import { NextRouter } from 'next/router'
import { Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { authenticatedApiFetch } from '../apiClient/apiClient'
import { ConfigServiceContext } from '../services/config/ConfigService'
import { UserServiceContext } from '../services/user/UserService'
import { ConvivaErrorSeverity, ErrorDetails as VideoError, ErrorButton as VideoErrorButton } from '../type/Error'

import { getAdInfo, getDeviceIsSafari, getDeviceIsXbox, getDeviceType } from './useDeviceInfo'
import { getIsDebug } from './useIsDebug'

export interface VideoSrc {
  videoId: string
  title: string
  src: string
  type: 'application/dash+xml' | 'application/x-mpegURL'
  isLive: boolean
  stream_type: 'linear' | 'vod'
  keySystems?: KeySystems
  heartbeat: Heartbeat
  analytics: Analytics
  conviva_analytics?: {
    [key: string]: any
  }
  raw: VideoResponse
}

export interface Heartbeat {
  interval: number
  link: Link
}

// All props are marked as optional since the payload may change depending
// on video type.
export interface VideoAnalyticsForGoogle {
  away_team?: string
  broadcast_team?: string
  content_region?: string
  content_type?: string
  first_air_date?: string
  first_air_time?: string
  first_air_timestamp?: string
  game_id?: string
  game_type?: string
  home_team?: string
  league?: string
  material_id?: string
  stream_type?: string
  streaming_method?: string
  team?: string
  video_duration?: number
  video_id?: string
  video_platform?: string
  video_title?: string
  video_type?: string
}

export interface Analytics extends VideoAnalyticsForGoogle {
  /* */
}

export interface HeartbeatResponse {
  message: string
}

export interface KeySystems {
  'com.widevine.alpha'?: DRMInfo
  'com.microsoft.playready'?: DRMInfo
  'com.apple.fps.1_0'?: any
}

export interface DRMInfo {
  url?: string
  licenseHeaders?: LicenseHeaders
}

export interface FairplayDRMInfo {
  licenseUri?: string
  certificateUri: string
  licenseHeaders: LicenseHeaders
  certificateHeaders: LicenseHeaders
}

export interface LicenseHeaders {
  'x-dt-auth-token'?: string
}

export interface VideoResponse {
  url: string
  title: string
  description: string
  islive: boolean
  stream_type: 'linear' | 'vod'
  drm?: DRM
  heart_beat: Heartbeat
  analytics: Analytics
  conviva_analytics?: {
    [key: string]: any
  }
  start_date: string
  end_date: string
  next_id?: string
}

export interface DRM {
  licenseUrl: string
  token: string
}

// ERROR types + classes
export type ButtonSize = 'min' | 'xs' | 'sm' | 'md' | 'lg'
export type ButtonStyle = 'primary' | 'alt' | 'on-image' | 'live'

// .video types

export type VideoState = {
  title: string
  url: string
  src: VideoSrc
  isLive: boolean
  keySystems?: KeySystems
  heartbeat?: Heartbeat
}
// .video DRM types
export interface DRM {
  licenseUrl: string
  token: string
}

export interface KeySystems {
  'com.widevine.alpha'?: DRMInfo
  'com.microsoft.playready'?: DRMInfo
  'com.apple.fps.1_0'?: any
}

export interface DRMInfo {
  url?: string
  licenseHeaders?: LicenseHeaders
}

export interface FairplayDRMInfo {
  licenseUri?: string
  certificateUri: string
  licenseHeaders: LicenseHeaders
  certificateHeaders: LicenseHeaders
}

export interface LicenseHeaders {
  'x-dt-auth-token'?: string
}

// heartbeat
export interface Heartbeat {
  interval: number
  link: Link
}

export interface HeartbeatResponse {
  message: string
}

export type VideoServiceProps = {
  videoId: string
  useRouter: () => NextRouter
  isDisabled?: boolean
}

export class VideoServiceError {
  status: number
  message: string
  code: string
  constructor({ message, status, code }: { message: string; status: number; code: string }) {
    this.message = message
    this.status = status
    this.code = code
  }
}

// Reducer for VideoService.state

type VideoStatus = 'loading' | 'success' | 'error'

export type DRMType = 'widevine' | 'playready' | 'fairplay'
export type DRMFormat = 'DASH' | 'HLS'

export type VideoService = {
  src: VideoSrc | null
  error: VideoError | null
  status: VideoStatus
  setError: Dispatch<SetStateAction<VideoError | null>>
  errorForVideoError: (e: VideoServiceError | undefined) => VideoError | null
  errorForMediaError: (e: MediaError | null | undefined) => VideoError | null
}

export const useVideoService = ({ videoId, useRouter, isDisabled }: VideoServiceProps): VideoService => {
  const router = useRouter()

  const { currentConfig, appVersion, apiEnvironment } = useContext(ConfigServiceContext)
  const { currentUser } = useContext(UserServiceContext)
  const [src, setSrc] = useState<VideoSrc | null>(null)
  const [error, setError] = useState<VideoError | null>(null)

  const status = useMemo<VideoStatus>(() => {
    if (error) {
      return 'error'
    } else {
      return src ? 'success' : 'loading'
    }
  }, [error, src])

  const errorForVideoError = useCallback(
    (e: VideoServiceError | undefined): VideoError | null => {
      if (e === undefined) {
        return null
      }

      const { message, status, code } = e
      const severity: ConvivaErrorSeverity = 1
      switch (status) {
        case 404:
          return new VideoError({
            title: 'Uh oh!',
            status,
            code,
            message,
            severity,
            buttons: [
              new VideoErrorButton({
                title: 'Return Home',
                action: () => router.replace('/')
              })
            ],
            tvButtons: [
              new VideoErrorButton({
                title: 'Return Home',
                action: () => router.replace('/home')
              })
            ]
          })
        case 429:
          const heartbeatError = new VideoError({
            title: 'Video Error',
            message: message,
            status,
            code,
            severity,
            buttons: [
              new VideoErrorButton({
                title: 'Manage Devices',
                action: () => {
                  router.push('/settings/devices')
                }
              })
            ]
          })
          return heartbeatError
        default:
          return new VideoError({
            title: 'Uh oh!',
            message,
            status,
            code,
            severity,
            buttons: [
              new VideoErrorButton({
                title: 'Retry',
                action: () => router.reload()
              })
            ],
            tvButtons: [
              new VideoErrorButton({
                title: 'Retry',
                action: () => router.reload()
              })
            ]
          })
      }
    },
    [router]
  )

  const errorForMediaError = useCallback(
    (e: MediaError | null | undefined): VideoError | null => {
      if (e === undefined || e === null) {
        return null
      }
      const severity: ConvivaErrorSeverity = 0
      const buttons: VideoErrorButton[] = [
        new VideoErrorButton({
          title: 'Retry',
          action: () => {
            router.reload()
          }
        })
      ]
      const defaultMessage = 'Something went wrong. Please try again.'
      if (typeof e === 'object' && e !== null) {
        const getVideoError = e as MediaError
        const status = getVideoError?.code ?? -999
        const message = getVideoError?.message ?? defaultMessage
        const code = `media_error_${status}`
        return new VideoError({
          title: 'Uh oh!',
          message,
          status,
          code,
          buttons,
          severity
        })
      } else if (typeof e === 'number') {
        return new VideoError({
          title: 'Uh oh!',
          message: `Unknown Media Error: ${e}`,
          status: e,
          code: `media_error_${e}`,
          buttons,
          severity
        })
      } else {
        const getVideoError = e as string
        return new VideoError({
          title: 'Uh oh!',
          message: `Unknown Media Error: ${getVideoError}`,
          status: 0,
          code: `media_error_0`,
          buttons,
          severity
        })
      }
    },
    [router]
  )

  useEffect(() => {
    if (error) {
      return
    }
    const getVideo = async () => {
      const configUrl = currentConfig?.API.services.video_services.playback
      const token = currentUser?.user_token
      if (configUrl === undefined || token === undefined) {
        return
      }
      const adInfo = await getAdInfo(apiEnvironment)
      const isSafari = getDeviceIsSafari()
      const isXbox = getDeviceIsXbox()

      const getUrl = (): string => {
        const baseUrl = `${configUrl}/${videoId}`

        const deviceType = getDeviceType()
        let drmType: DRMType = 'widevine'
        let format: string = 'DASH'
        switch (deviceType) {
          case 'tv_xboxone':
            drmType = 'playready'
            break
          case 'web_browser':
            if (isSafari) {
              format = 'HLS'
              drmType = 'fairplay'
            }
            break
        }

        const params: { [key: string]: any } = {
          drmType,
          format,
          appversion: appVersion,
          debug: getIsDebug(apiEnvironment),
          ...adInfo
        }

        const favTeams = currentUser?.profile.favorites.teams

        if (favTeams && favTeams.length > 0) {
          params.favteams = favTeams.join(',')
        }

        // set chromecast param if on xbox
        if (isXbox) {
          params.chromecast = true
        }
        if (params.did === '') {
          delete params.did
        }
        // convert params to string
        const paramsString = new URLSearchParams(params).toString()
        const url = `${baseUrl}?${paramsString}`
        console.log('adinfo troubleshooting > useVideoService > getUrl ', { params, paramsString, url })
        return `${baseUrl}?${paramsString}`
      }
      const res = await authenticatedApiFetch({
        url: getUrl(),
        token,
        method: 'GET'
      })

      if (res.ok) {
        const body = (await res.json()) as VideoResponse
        const keySystems = (): KeySystems | undefined => {
          const drm = body.drm
          if (drm !== undefined) {
            var headers = {
              'x-dt-auth-token': drm.token
            }
            return {
              'com.widevine.alpha': {
                url: drm.licenseUrl,
                licenseHeaders: headers
              },
              'com.apple.fps.1_0': {
                getContentId: function (emeOptions: any, initData: any) {
                  return initData
                },
                getCertificate: async function (emeOptions: any, callback: (e: any, c: any) => void) {
                  let url = new URL(drm.licenseUrl)
                  url.pathname += 'cert/ballysports'
                  const res = await fetch(url.toString(), {
                    method: 'GET',
                    headers: {
                      ...headers
                    }
                  })
                  const responseBody = await res.arrayBuffer()
                  callback(null, new Uint8Array(responseBody))
                },
                getLicense: async function (
                  emeOptions: any,
                  contentId: any,
                  keyMessage: any,
                  callback: (e: any, c: any) => void
                ) {
                  const res = await fetch(drm.licenseUrl, {
                    method: 'POST',
                    body: keyMessage,
                    headers: {
                      ...headers
                    }
                  })
                  const responseBody = await res.text()
                  let rawLicenseString = atob(responseBody)
                  let data = new Uint8Array(rawLicenseString.length)
                  for (let i = 0; i < rawLicenseString.length; ++i) {
                    data[i] = rawLicenseString.charCodeAt(i)
                  }
                  let key = data.buffer
                  callback(null, key)
                }
              }
            }
          } else {
            return {}
          }
        }

        // formats are DASH or hls_v3
        const nextSrc: VideoSrc = {
          videoId,
          raw: body,
          title: body.title,
          type: isSafari ? 'application/x-mpegURL' : 'application/dash+xml',
          src: body.url,
          keySystems: keySystems(),
          heartbeat: body.heart_beat,
          analytics: body.analytics,
          conviva_analytics: body.conviva_analytics,
          isLive: body.islive,
          stream_type: body.stream_type
        }
        setSrc(nextSrc)
        setError(null)
      } else {
        const e = await res.json()
        setError(
          errorForVideoError({
            message: e?.message ?? 'Unknown Error',
            status: e?.status ?? res?.status ?? 500,
            code: e?.code ?? 'unknown_video_service_error'
          })
        )
        setSrc(null)
      }
    }

    // get video
    getVideo()
    return () => {
      /* */
    }
  }, [
    currentUser?.profile.favorites.teams,
    currentUser?.user_token,
    currentConfig?.API.services.video_services.playback,
    videoId,
    errorForVideoError,
    error,
    appVersion,
    apiEnvironment
  ])

  useEffect(() => {
    const heartbeat = src?.heartbeat
    const token = currentUser?.user_token
    if (error || !heartbeat || !token || isDisabled) {
      return () => {
        /* */
      }
    }
    const url = heartbeat.link.url

    const getHeartbeat = async () => {
      if (error) {
        return
      }
      try {
        const res = await authenticatedApiFetch({
          method: 'POST',
          url,
          token
        })
        if (res.ok) {
          // Heartbeat successful!
          return
        }
        const data = await res.json()
        // TODO: stop video playback, if the error data informs us it's one worth stopping for.
        const videoError = errorForVideoError({
          message: data?.message ?? 'Unknown Heartbeat Error',
          status: data?.status ?? res?.status ?? 500,
          code: data?.code ?? 'unknown_heartbeat_error'
        })
        if (data?.status == 429) {
          setError(videoError)
          return
        }
        throw videoError
      } catch (e) {
        console.error(e)
      }
    }

    getHeartbeat()

    // get heartbeat every ms
    const ms = heartbeat?.interval ?? 500
    const heartbeatInterval = setInterval(() => {
      getHeartbeat()
    }, ms)
    return () => {
      clearInterval(heartbeatInterval)
    }
  }, [currentUser?.user_token, error, errorForVideoError, src?.heartbeat, isDisabled])

  return {
    src,
    error,
    status,
    setError,
    errorForMediaError,
    errorForVideoError
  }
}
export { VideoError, VideoErrorButton }
